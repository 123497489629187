@import '../../../assets/base_css/_animations.css';

.detailV5 {
  --transparent: rgba(24, 24, 24, 0);
  --color_detail-cover-background: linear-gradient(
    to bottom,
    var(--transparent) 0%,
    #181818 63%
  );
  --color_detail-cover-background-compact: linear-gradient(
    to bottom,
    var(--transparent) 0%,
    #181818 56%
  );
  --color_detail-cover-background-regular: linear-gradient(
    to bottom,
    var(--transparent) 0%,
    #181818 63%
  );
  --color_detail-cover-background-TV: linear-gradient(
    to bottom,
    var(--transparent) 0%,
    #181818 75%
  );
  --color_detail-cover-from-tablet-background: linear-gradient(
    0deg,
    rgba(33, 36, 41, 1) 0%,
    rgba(27, 28, 29, 1) 20%,
    var(--transparent) 100%
  );
  --color_detail-tabsWrapper-bg: var(--color_background-v5);

  [data-theme~='telecomitalia'] & {
    --color_detail-cover-background: linear-gradient(
      to bottom,
      var(--transparent) 0%,
      var(--color_gold-dark-primary) 63%
    );
    --color_detail-cover-background-compact: linear-gradient(
      to bottom,
      var(--transparent) 0%,
      var(--color_gold-dark-primary) 56%
    );
    --color_detail-cover-background-regular: linear-gradient(
      to bottom,
      var(--transparent) 0%,
      var(--color_gold-dark-primary) 63%
    );
    --color_detail-cover-background-TV: linear-gradient(
      to bottom,
      var(--transparent) 0%,
      var(--color_gold-dark-primary) 75%
    );
    --color_detail-cover-from-tablet-background: linear-gradient(
      180deg,
      var(--transparent) 0%,
      var(--color_gold-dark-primary) 95%
    );
    --color_detail-tabsWrapper-bg: var(--color_gold-kumera);
  }

  background-color: var(--color_detail-tabsWrapper-bg);

  --horizontal-padding: 1.5rem;
  --tabslist-height: 4rem;
  --detail-linear-gradient-height: 14.8125rem;
  --action-layout-btn-width: 21.5625rem;
  --url-logo-height: 4.3125rem;

  @media (--mq-from-tablet) {
    --detail-linear-gradient-height: 26.0625rem;
  }

  @mixin tvDevice {
    min-height: 100vh;
    --tabslist-height: 6.625rem;
    --detail-linear-gradient-height: 43rem;
    --url-logo-height: 8.75rem;
  }

  &--focus {
    @mixin tvDevice {
      list-style: none;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color_detail-content);

    @media (--mq-from-tablet) {
      display: flex;
      flex-direction: column;
      min-height: var(--detail-linear-gradient-height);
      max-height: calc(100vh - var(--tabslist-height));

      &-immersive {
        max-height: calc(
          100vh - var(--tabslist-height) - 1.5rem
        ); /* - immersive padding-top */
      }
    }

    @mixin tvDevice {
      max-height: calc(100vh - var(--tabslist-height));
    }
  }

  &__coverWrapper {
    position: relative;
    max-height: 100%;

    /* Create linear Gradient for cover */
    &::after {
      position: absolute;
      right: 0;
      bottom: -2px; /* avoid empty-line display bug when divs misalign vertically */
      left: 0;
      z-index: var(--z-index-shadow);
      width: 100%;
      height: var(--detail-linear-gradient-height);
      content: '';
      background: var(--color_detail-cover-background-compact);
    }

    &--compact-ratio-169 {
      --compact-169-bottom-correction: 6.625rem;
      /* On mobile, the design of 16/9 assets is cropped on the left and right, resulting in a 4/3-like cover
      https://www.figma.com/file/QMbTqDWzaZjdNYkRoI5dFX/Detail-page-V5---Web?node-id=1354%3A39404&t=gpEL9iMpHArnfn4b-1
      */
      @mixin aspectRatioLegacy 4/3;

      margin-bottom: calc(
        -1 * var(--detail-linear-gradient-height) +
          var(--compact-169-bottom-correction)
      );

      /* Linear gradient */
      &::after {
        margin-bottom: calc(-1 * var(--compact-169-bottom-correction));
      }
    }

    &--compact-ratio-34 {
      @mixin aspectRatioLegacy 3/4;
      margin-bottom: calc(-1 * var(--detail-linear-gradient-height));
    }

    @media (--mq-from-tablet) {
      &--regular-ratio-169 {
        @mixin aspectRatioLegacy 16/9;
        margin-bottom: 9.125rem;

        /* Linear gradient */
        &::after {
          display: none; /* managed by actionsWrapper::after */
        }
      }
    }

    @mixin tvDevice {
      &--regular-ratio-169 {
        margin-bottom: unset;
      }
    }
  }

  &__actionsWrapper {
    position: initial;
    z-index: var(--z-index-content);
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 0 var(--horizontal-padding) var(--horizontal-padding);
    background: var(--color_gold-dark-primary);

    @media (--mq-from-tablet) {
      position: absolute;
      bottom: 0;
      background: none;
    }

    /* Create linear Gradient for cover */
    &::after {
      position: absolute;
      right: 0;
      bottom: -2px; /* avoid h-line between actions and TabsList */
      left: 0;
      z-index: var(--z-index-shadow);
      width: 100%;
      height: 0; /* Mobile managed by coverWrapper::after */
      content: '';
      background: var(--color_detail-cover-background-regular);
    }

    &__content {
      --max-width-tabs-wrapper-ssr: 73.75rem;
      @media (--mq-below-height-threshold) {
        --max-width-tabs-wrapper-ssr: 58.75rem;
      }
      position: relative;
      z-index: var(--z-index-content);
      width: 100%;
      max-width: var(--max-width-tabs-wrapper-ssr); /* 1180px or 940px */
      margin: 0 auto;
      clip-path: none;

      @mixin tvDevice {
        max-width: 100%;
        padding: 5.625rem 5.625rem 2.5rem;
      }
    }

    @media (--mq-from-tablet) {
      padding: 0 2rem 1.875rem;

      /* Create linear Gradient for cover */
      &::after {
        height: var(--detail-linear-gradient-height);
      }
    }

    @media (--mq-from-desktop) {
      padding: 0 4rem 1.875rem;

      @media (--mq-below-height-threshold) {
        padding: 0 2rem 1.875rem;
      }
    }

    @mixin tvDevice {
      position: absolute;
      padding: 0;

      /* Create linear Gradient for cover */
      &::after {
        height: var(--detail-linear-gradient-height);
        background: var(--color_detail-cover-background-TV);
      }
    }
  }

  &__actionsTrailer {
    position: absolute;
    top: 2.5rem;
    right: calc(-1 * (var(--horizontal-padding) / 2));

    @media (--mq-from-tablet) {
      top: auto;
      right: 0;
      bottom: calc(var(--url-logo-height) + 11rem);
    }
  }

  &__editorialWrapper {
    display: flex;

    visibility: hidden;
    align-items: center;
    margin-bottom: 0.375rem;
    color: var(--color_detail-metas);

    @media (--mq-from-tablet) {
      margin-bottom: 1.25rem;
    }

    @mixin tvDevice {
      margin: 0 0 1.25rem 0.875rem;
    }

    & svg {
      height: 0.8125rem;
      fill: var(--color_detail-metas);

      @mixin tvDevice {
        height: 1.375rem;
        margin-right: 1rem;
      }
    }
    &--fadeIn {
      @mixin fadeInAnimation;
    }
  }

  &__editorialAndTechnical {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    & > * {
      margin-top: 0.3125rem;
      margin-right: 0.625rem;

      @mixin tvDevice {
        margin-top: 0.625rem;
      }
    }

    span {
      display: flex;
      align-items: center;

      svg {
        margin-bottom: 0.25rem;
      }
    }
  }

  &__URLLogo {
    display: none;
    @media (--mq-from-tablet) {
      display: block;
    }

    img {
      position: absolute;
      right: 0;

      @media (--mq-from-tablet) {
        height: var(--url-logo-height);
        margin-top: calc(-1 * (var(--url-logo-height) + 0.5rem));

        @mixin tvDevice {
          height: var(--url-logo-height);
          margin-top: calc(-1 * (var(--url-logo-height) + 1rem));
        }
      }
    }
  }

  &__actionsURLLogo {
    margin-left: auto;
    @media (--mq-from-tablet) {
      display: none;
    }

    img {
      height: 2.8125rem;
    }
  }

  &__actionsInformationsWrapper {
    position: relative;

    @media (--mq-from-tablet) {
      display: flex;
    }
  }

  &__actionLayoutInfos {
    z-index: 1;
    visibility: hidden;
    min-height: 7.125rem;
    &--fadeIn {
      @mixin fadeInAnimation;
    }
  }

  &__informations {
    visibility: hidden;
    margin-top: 1.375rem;
    &--fadeIn {
      @mixin fadeInAnimation;
    }

    @media (--mq-from-tablet) {
      display: flex;
      flex: 1 1 66.66%;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 1.5rem;
      margin-top: unset;
    }

    @media (--mq-from-laptop) {
      padding-left: 1.75rem;
    }
    @media (--mq-from-desktop) {
      padding-left: 1.875rem;
    }
    @mixin tvDevice {
      flex: 1; /* should take available space, even if description text is too small */
      padding-left: 3.75rem;
    }
  }

  &__metadatas {
    display: flex;
    flex-direction: column;

    @media (--mq-from-tablet) {
      flex-direction: row;
    }

    &__left {
      margin: 0.5rem 0 0.875rem;

      @media (--mq-from-tablet) {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 0.625rem;
        margin: unset;
        margin-bottom: unset;
      }

      @mixin tvDevice {
        height: unset;
        min-height: 6.25rem;
        padding-top: unset;
      }

      &__textList {
        padding-bottom: 0.5rem;
      }
    }
  }

  &__actionLayout {
    z-index: var(--z-index-content);

    @media (--mq-from-tablet) {
      width: var(--action-layout-btn-width);
      min-width: var(--action-layout-btn-width);
    }

    @mixin tvDevice {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 38.75rem;
      margin-bottom: unset;
    }
  }
}
