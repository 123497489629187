.cover {
  --tabslist-height: 4rem;
  @mixin tvDevice {
    --tabslist-height: 6.625rem;
  }

  &--compact-ratio-169 {
    img {
      object-fit: cover;
    }
  }

  @media (--mq-from-tablet) {
    &--regular-ratio-169 {
      height: 100%;
      max-height: calc(100vh - var(--tabslist-height));

      img {
        object-fit: cover;
        object-position: top;
      }
    }
  }

  @mixin tvDevice {
    height: calc(100vh - var(--tabslist-height));
    padding-top: unset;

    img {
      object-fit: cover;
    }
  }
}
