.editorialTitle_text {
  font-size: 0.75rem;
  line-height: 1rem;
  white-space: pre-wrap;
  @mixin tvDevice {
    margin-right: 1.0625rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}
