@import '../../../../assets/base_css/_animations.css';

.stickyTitle {
  position: fixed;
  top: 0;
  z-index: var(--z-index-title);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--size_detailv5_stickyTitle_height);
  padding: 0 4rem;
  font-family: var(--font_hind);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  background-color: var(--color_background-v5);
  opacity: 0;

  &--css-fadein-fallback {
    opacity: 1;
  }

  @media (--mq-from-tablet) {
    width: var(--size_detailv5_immersive_width);
    font-size: 1.375rem;
    line-height: 1.625rem;
  }

  @media (--mq-from-desktop) {
    max-width: 80rem;

    @media (--mq-below-height-threshold) {
      max-width: 62.5rem;
    }
  }

  &--fadeIn {
    display: flex;
    @mixin fadeInAnimation;
  }

  span {
    @mixin lineClamp 1;
  }
}
