@import '../../../../assets/base_css/_animations.css';

:root {
  /* -1px to prevent transparent line bug between StickyTitle & Tabs  */
  --tabsList-topOffset-mobile: calc(
    var(--size_mobileHeader_height) - 1px
  ); /* Mobile header SSR */
  --tabsList-topOffset: calc(
    var(--size_header_height) - 1px
  ); /* Desktop header SSR*/
}

.tabsAnchor {
  --tabsAnchor-height: var(--tabsList-topOffset-mobile);
  @media (--mq-from-tablet) {
    --tabsAnchor-height: var(--tabsList-topOffset);
  }
  @mixin tvDevice {
    --tabsAnchor-height: 1.5625rem; /* 25px visible offset */
  }

  &.tabsAnchor--immersive {
    --tabsAnchor-height: var(
      --size_detailv5_stickyTitle_height
    ); /* StickyTitle height */
    @media (--mq-from-tablet) {
      --tabsAnchor-height: 2rem; /* StickyTitle height - 32px Padding-top Immersive */
    }
  }

  position: absolute;
  display: block;
  height: var(--tabsAnchor-height);
  margin-top: calc(-1 * var(--tabsAnchor-height));
}

.tabsListWrapper {
  position: sticky;
  top: var(--tabsList-topOffset-mobile);
  z-index: var(--z-index-nav);

  &.tabsListWrapper--immersive {
    top: var(--size_detailv5_stickyTitle_height);
  }

  @media (--mq-from-tablet) {
    top: var(--tabsList-topOffset);
    bottom: 0; /* Only sticky to bottom tablet+ */
  }

  @mixin tvDevice {
    position: relative; /* Only relative work with old TV devices Like LG 2019 */
    top: 0; /* offset not needed for TV */
    padding: 0 5.625rem;
  }
}

.tabsPanelWrapper {
  position: relative;
  min-height: 100vh;

  &.tabsPanelWrapper__hidden {
    opacity: 0;
  }
}

.tabsWrapper {
  width: 100%;
  background-color: var(--color_detail-tabsWrapper-bg);

  .tabsWrapper__content {
    --max-width-tabs-wrapper-ssr: 73.75rem;
    @media (--mq-below-height-threshold) {
      --max-width-tabs-wrapper-ssr: 58.75rem;
    }
    visibility: hidden;

    max-width: var(--max-width-tabs-wrapper-ssr);
    margin: auto;

    &.tabsWrapper__content--fadeIn {
      @mixin fadeInAnimation;
    }

    @media (--mq-from-tablet) {
      max-width: calc(var(--max-width-tabs-wrapper-ssr) + calc(2rem * 2));
    }

    @media (--mq-from-desktop) {
      max-width: calc(var(--max-width-tabs-wrapper-ssr) + calc(4rem * 2));

      @media (--mq-below-height-threshold) {
        max-width: calc(var(--max-width-tabs-wrapper-ssr) + calc(2rem * 2));
      }
    }

    @mixin tvDevice {
      max-width: 100%;
    }
  }
}
