.tabsList {
  --color_tabsList-fg: var(--color_white-30);

  [data-theme~='telecomitalia'] & {
    --color_tabsList-fg: var(--color_white-70);
  }
  display: flex;
  padding: 1rem;

  overflow-x: scroll;
  overflow-y: hidden;

  font-family: var(--font_canal);
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--color_tabsList-fg);
  @mixin hideScrollbar;

  @media (--mq-from-tablet) {
    padding: 1.3125rem 2rem;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
  @media (--mq-from-desktop) {
    padding: 1.3125rem 4rem;

    @media (--mq-below-height-threshold) {
      padding: 1.3125rem 2rem;
    }
  }
  @mixin tvDevice {
    padding: 1.25rem 0;
  }

  & > * {
    flex-shrink: 0;
    margin-right: 2rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    @media (--mq-from-tablet) {
      margin-right: 2.5rem;
    }
    @mixin tvDevice {
      margin-right: 0;
    }
  }
}

/* TV focus style */
:global {
  .tabsListFocus .tv__focus {
    @mixin tv-focus-button-base;
  }
}
