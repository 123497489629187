.moreInfosButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 6.5625rem;
  height: 2.125rem;
  color: var(--color_detail-moreInfosButton-fg);
  background-color: var(--color_detail-moreInfosButton-bg);
  border-radius: 0.25rem;

  @media (--mq-from-tablet) {
    justify-content: unset;
    width: max-content;

    @mixin tvDevice {
      display: block;
      height: 4.875rem;
      padding: 1.375rem 2.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 0.5rem;
    }
  }

  &:hover {
    @mixin tvDevice {
      opacity: unset;
    }

    @mixin genericFocusColor var(--color_detail-moreInfosButton-hover-bg),
      background;
    transition-timing-function: ease-out;
  }

  &--focus {
    @mixin tvDevice {
      list-style: none;
    }
  }

  &__wrapper {
    --color_detail-moreInfosButton-bg: var(--color_mine-shaft);
    --color_detail-moreInfosButton-hover-bg: var(--color_grey-500);
    --color_detail-moreInfosButton-fg: var(--color_white);

    [data-theme~='telecomitalia'] & {
      --color_detail-moreInfosButton-bg: var(--color_gold-dark-secondary);
      --color_detail-moreInfosButton-hover-bg: var(--color_gold-dark-tertiary);
      --color_detail-moreInfosButton-fg: var(--color_white);
    }

    width: 100%;

    @media (--mq-from-tablet) {
      display: flex;
      flex-direction: row-reverse;
      align-self: flex-end;
      width: auto;
    }
  }

  &__text {
    min-width: inherit;
    padding: 0 0.625rem;
    font-family: var(--font_hind);
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: center;

    @mixin lineClamp 1, 1.8;

    @mixin tvDevice {
      height: 4.875rem;
      margin: 0 auto;
      font-family: var(--font_hind);
      font-size: 1.6875rem;
      line-height: 2rem;

      @mixin lineClamp 1, 3.2;
    }
  }

  &:global(.tv__focus) {
    @mixin tv-focus-button;
    color: var(--color_focus-fg);
    background-color: var(--color_secondary);
    transform: scale(1.04);

    svg {
      fill: var(--color_focus-fg);
    }
  }
}
