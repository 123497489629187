.tab {
  &:hover,
  &--active {
    color: var(--color_white);
  }

  a {
    padding: 1rem 0;
    text-transform: uppercase;

    @media (--mq-from-tablet) {
      padding: 1.3125rem 0;
    }

    @mixin tvDevice {
      display: inline-block;
      padding: 0.9375rem 2.8125rem;
      font-size: 2rem;
      line-height: 2.25rem;
      border-radius: 0.5rem;
    }

    h1 {
      @mixin resetH1;
    }
  }
}
