.sharing {
  --color_twitter-svg-icon: var(--color_white);

  --color_facebook-svg-icon: var(--color_white);
  --color_facebook-svg-icon-bg: #1877f2;

  &__title {
    margin: 0;
    font-size: 1.0625rem;
  }

  &__items {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
  }

  &__item {
    color: var(--color_grey-300);
    text-align: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 3.5rem;
    }

    &:hover {
      color: var(--color_white);
    }
  }

  &__icon {
    @mixin size 3.375rem;
    transition: opacity 300ms ease-in-out;

    &__facebook {
      background-color: var(--color_facebook-svg-icon-bg);
      border-radius: 1.6875rem;
      fill: var(--color_facebook-svg-icon);
    }

    &__twitter {
      fill: var(--color_twitter-svg-icon);
    }
  }

  &__label {
    display: block;
    margin-top: 0.8125rem;
    font-size: 0.8125rem;
  }
}
