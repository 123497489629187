.primaryActions {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 0.5rem;

    @mixin tvDevice {
      margin-bottom: 1rem;
    }
  }

  &__remainingTimes {
    display: flex;
    align-items: center;
    margin-top: -0.125rem;

    @mixin tvDevice {
      margin-top: -0.375rem;
      margin-bottom: 0.375rem;
    }
  }

  &__remainingTimeLabel {
    width: 30%;
    font-size: 0.75rem;
    color: var(--color_detail-metas);
    text-align: right;

    @mixin tvDevice {
      width: auto;
      margin-left: 0.75rem;
      font-size: 1.6875rem;
      line-height: 2.0625rem;
    }
  }

  &__liveContainer {
    display: flex;
    margin-left: 0.625rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--color_white-50);

    @mixin tvDevice {
      font-size: 1.6875rem;
      line-height: 2rem;
    }

    > *:not(:last-child) {
      margin-right: 0.3125rem;
    }
  }

  &__liveText {
    font-family: var(--font_hind);
    font-size: 0.75rem;
    line-height: 1rem;
    opacity: 0.5;

    @mixin tvDevice {
      font-size: 1.6875rem;
      line-height: 2rem;
    }
  }

  &__liveCircle {
    margin: auto 0;
    background-color: var(--color_maroon-flush);
    border-radius: 50%;
    @mixin size 0.375rem;

    @mixin tvDevice {
      @mixin size 0.625rem;
    }
  }

  /* weird special case were button is wrapped in a Linker */
  &__link:global(.tv__focus) > button {
    @mixin tv-focus-button;
    background-color: var(--color_secondary);

    svg {
      fill: var(--color_focus-fg);
    }
  }
}
