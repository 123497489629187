.metadatas {
  --color_metadatas-fg: var(--color_white-50);

  [data-theme~='telecomitalia'] & {
    --color_metadatas-fg: var(--color_white-70);
  }

  font-size: 0.875rem;
  line-height: 1.125rem;

  @mixin tvDevice {
    font-size: 1.6875rem;
    line-height: 1.875rem;
  }

  @mixin gapLegacy 0, 0.3125rem;

  &__list {
    color: var(--color_metadatas-fg);

    @mixin tvDevice {
      @mixin lineClamp 1, 3;
    }

    &__prefix {
      display: inline-block;
      color: var(--color_metadatas-fg);
    }

    &__item {
      a {
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: var(--color_metadatas-fg);
        transition: color 300ms ease-in;

        @media (--mq-from-tablet) {
          @mixin tvDevice {
            font-size: 1.6875rem;
            line-height: 1.875rem;
          }
        }

        &:hover {
          @mixin genericFocusColor var(--color_white);
          transition-timing-function: ease-out;
        }
      }
    }
  }

  &__prodNationality {
    @media (--mq-from-tablet) {
      @mixin lineClamp 1;
    }
  }

  &__personality {
    @mixin lineClamp 1, 1.8;
  }
}
