.badge {
  @mixin size 3.4375rem, 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color_white);
  border-radius: var(--border-radius-small);
  transition: background 300ms ease-in-out;

  svg {
    transition: fill 300ms ease-in-out;
  }

  &--button {
    background-color: var(--color_badge-button-bg);

    &.badge--hasHover {
      &:hover {
        @mixin genericFocusColor var(--color_badge-button-hover-bg);
      }

      [data-theme~='telecomitalia'] & {
        &:hover {
          background-color: var(--color_badge-button-hover-bg);
          opacity: 1;
        }
      }
    }

    svg {
      height: 1.6875rem;
      fill: var(--color_badge-button-svg-fill);
      @mixin tvDevice {
        fill: var(--color_white);
      }
    }

    &.badge--disabled {
      background-color: var(--color_badge-button-disabled-bg);

      &:hover {
        background-color: var(--color_badge-button-disabled-hover-bg);
        opacity: 1;
      }

      svg {
        fill: var(--color_badge-button-disabled-svg-fill);
        @mixin tvDevice {
          fill: var(--color_white);
        }
      }
    }

    /** 
     * class is doubled here to augment selector specificity and override svg color set in
     * MoodButton.css when focused
     */
    :global(.tv__focus.tv__focus) > & {
      @mixin tv-focus-button;
      color: var(--color_focus-fg);
      background-color: var(--color_secondary);

      & > svg {
        fill: var(--color_focus-fg);
      }
    }
  }

  &--facebook,
  &--twitter {
    svg {
      height: 1.375rem;
      fill: var(--color_white);
    }

    &.badge--disabled svg {
      fill: hsl(0, 0%, 50%); /* #808080 */
    }
  }

  &--facebook {
    background-color: var(--color_facebook);

    &:hover {
      @mixin genericFocusColor var(--color_facebook), background;
    }

    &.badge--disabled {
      background-color: hsl(221, 44%, 27%); /* #263962 */

      &:hover {
        background-color: hsl(221, 44%, 27%); /* #263962 */
        opacity: 1;
      }
    }
  }

  &--twitter {
    background-color: var(--color_twitter);

    &:hover {
      @mixin genericFocusColor var(--color_twitter), background;
    }

    &.badge--disabled {
      background-color: hsl(196, 100%, 30%); /* #007099 */

      &:hover {
        background-color: hsl(196, 100%, 30%); /* #007099 */
        opacity: 1;
      }
    }
  }

  &--rounded {
    border-radius: 50%;
  }

  &--small {
    @mixin size 1.875rem;

    &.badge--button {
      width: 2.8125rem;
    }

    span {
      height: 100%;

      svg {
        height: 1.375rem;
      }
    }
  }

  &--medium {
    @mixin size 2.3125rem;
  }

  &--large {
    @mixin size 3.5625rem;

    svg {
      height: 1.8125rem;
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &.badge--active {
    svg {
      fill: var(--color_white);
    }
  }

  /** overrides height set previously */
  &--twitter svg {
    height: 1.25rem;
  }

  &--download span {
    height: 1.5625rem;
  }
}
