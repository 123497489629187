.Reviews {
  --gap-x-tv: 1rem;
  --color_reviews-fg: var(--color_white-50);

  [data-theme~='telecomitalia'] & {
    --color_reviews-fg: var(--color_white-70);
  }

  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  @mixin gapLegacy -0.0625rem, 0.625rem;

  @media (--mq-from-tablet) {
    padding: 0 0.125rem 0 0;
  }
  @media (--mq-from-laptop) {
    padding: 0 1.25rem 0 0;
    @mixin gapLegacy 0, 1.25rem;
  }

  @mixin tvDevice {
    padding-top: 1rem;
    @mixin gapLegacy 0.25rem, var(--gap-x-tv);
  }

  svg {
    pointer-events: none;
  }

  &__item {
    display: flex;

    @media (--mq-from-laptop) {
      margin-top: -0.125rem;
    }

    @mixin tvDevice {
      line-height: 1.875rem;
      /* dpv5 tv specs: Telerama ratings use twice the horizontal gap of other .Reviews__item */
      .Reviews__rating--telerama {
        margin-right: var(--gap-x-tv);
      }
      &:not(:first-of-type) {
        .Reviews__rating--telerama {
          margin-left: var(--gap-x-tv);
        }
      }
    }
  }

  &__name {
    padding-right: 0.1875rem;
    font-size: 0.75rem;
    color: var(--color_reviews-fg);
    @mixin tvDevice {
      font-size: 1.6875rem;
    }
  }

  &__rating {
    height: 0.635rem;
    margin-top: 0.125rem;

    @mixin tvDevice {
      height: 1.25rem;
      margin-left: 0.25rem;
    }
  }
}
