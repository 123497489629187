.D2GUnavailableModal {
  @mixin size 100%;
  box-sizing: border-box;
  margin-top: 0.5rem;
  color: var(--color_white);
  text-align: center;
  @media (--mq-from-tablet) {
    padding-bottom: 0.625rem;
    margin-top: 1.25rem;
  }

  &__title {
    display: block;
    width: 100%;
    max-width: 28.75rem;
    padding: 0 1.875rem;
    margin: auto;
    font-family: var(--font_hind);
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;

    @media (--mq-from-tablet) {
      padding: 0 1.5625rem 0.625rem;
      font-size: 1.625rem;
    }
  }

  &__infos {
    font-size: 0.875rem;
    line-height: 1.25rem;
    @media (--mq-from-tablet) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &__button {
    width: 100%;
    height: 3rem;
    padding: 0.75rem 1rem;
    font-family: var(--font_canal);
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: var(--color_white-80);
    text-transform: uppercase;
    border-radius: 0.25rem;
  }
  &__icons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 1.5rem;

    a:nth-child(2) {
      padding: 1.5rem 0;
      @media (--mq-from-tablet) {
        padding: 0;
      }
    }
    @media (--mq-from-tablet) {
      flex-direction: row;
      padding-top: 1.625rem;
    }

    svg {
      height: 2.5rem;
    }
  }
}
