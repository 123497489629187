.mood {
  @mixin size 100%;
  box-sizing: border-box;
  padding: 0.625rem 0;

  &__title {
    display: block;
    width: 100%;
    max-width: 28.75rem;
    padding: 0 1.875rem;
    margin: auto;
    font-family: var(--font_hind);
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--color_white);
    text-align: center;

    @media (--mq-from-tablet) {
      padding: 0.625rem 1.5625rem;
      font-size: 1.625rem;
    }
  }

  &__items {
    display: flex;
    margin-top: 1.25rem;

    @mixin tvDevice {
      justify-content: center;
    }
  }

  &__item {
    @mixin size 6.25rem, auto;
    flex: 1;
    align-self: flex-start;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;

    @media (--mq-from-tablet) {
      @mixin size 8.125rem, auto;
      margin-right: 1.875rem;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover svg,
    &--active svg {
      opacity: 1;
    }

    &__title {
      display: block;
      width: 100%;
      opacity: 0.5;

      @mixin tvDevice {
        font-size: 1.6875rem;
      }
    }

    &--active {
      &.mood__item--neutral svg {
        fill: var(--color_mood-neutral);
      }

      &.mood__item--like svg {
        fill: var(--color_mood-like);
      }

      &.mood__item--dislike svg {
        fill: var(--color_mood-dislike);
      }
    }

    @mixin tvDevice {
      max-width: 15rem;
      height: 15rem;
      margin: 0 1rem;
    }
  }

  &__icon {
    @mixin size 3.75rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    opacity: 0.85;
    fill: var(--color_grey-200);
    transition: opacity 300ms ease-in-out;

    @media (--mq-from-tablet) {
      @mixin size 4.375rem;
    }

    @mixin tvDevice {
      @mixin size 6.25rem;
      fill: var(--color_grey-200);
    }
  }

  &__button {
    @mixin size 100%;
    color: var(--color_white);
  }
}

:global {
  @mixin tvDevice {
    .globalMoodButton {
      &.tv__focus {
        position: relative;
        background-color: var(--color_mood-btn-bg-tv-focus);
        border: 3px solid var(--color_focus-outline);
        border-radius: var(--border-radius-big-outline);

        svg,
        span {
          opacity: 1;
          transition: none;
        }
      }
    }
  }
}
