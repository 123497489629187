.summary {
  --color_summary-fg: var(--color_black-80);

  [data-theme~='telecomitalia'] & {
    --color_summary-fg: var(--color_white-80);
  }

  width: 100%;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--color_summary-fg);

  @mixin lineClamp 4, 2;

  @media (--mq-from-tablet) {
    @mixin lineClamp 3, 2.2;
  }

  @mixin tvDevice {
    max-width: 66.25rem;
    max-height: calc(2.25rem * 3);
    margin-bottom: 1rem;
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }

  span {
    font-family: var(--font_hind);
    font-weight: 600;
    color: var(--color_white);
  }
}
