.tabPanel {
  --TP-horizontal-padding-small: 1rem;
  --TP-horizontal-padding-tablet: 2rem;
  --TP-horizontal-padding-desktop: 4rem;

  &--no-padding {
    --TP-horizontal-padding-small: 0;
    --TP-horizontal-padding-tablet: 0;
    --TP-horizontal-padding-desktop: 0;
  }

  padding: 0.5rem var(--TP-horizontal-padding-small) 1rem;

  @media (--mq-from-tablet) {
    padding: 0.5rem var(--TP-horizontal-padding-tablet) 1.5rem;
  }

  @media (--mq-from-desktop) {
    padding: 0.75rem var(--TP-horizontal-padding-desktop) 1.5rem;

    @media (--mq-below-height-threshold) {
      padding: 0.75rem var(--TP-horizontal-padding-tablet) 1.5rem;
    }
  }
}
