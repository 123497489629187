.secondaryActions {
  --color_secondaryActions-fg: var(--color_black-50);
  --color_secondaryActions-bg: var(--color_mine-shaft);

  [data-theme~='dark'] & {
    --color_secondaryActions-fg: var(--color_white-50);
  }

  [data-theme~='telecomitalia'] & {
    --color_secondaryActions-fg: var(--color_white);
    --color_secondaryActions-bg: var(--color_gold-dark-secondary);
  }

  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0.875rem 0 2.375rem;

  @media (--mq-from-tablet) {
    height: var(--size_detailv5_action_layout_bottom_height);
    margin: unset;
  }

  @mixin tvDevice {
    justify-content: space-between;
    height: unset;
    margin-top: 1.5rem;

    li {
      flex: 1;
      width: 100%;
      overflow: hidden;
      border-radius: var(--dt-border-ultra-strong);
    }

    /* 2rem gap between buttons on tv */
    & > *:not(:last-child) {
      margin-right: 1.25rem;
    }
  }

  &__playlistBtnTv,
  &__recordingBtnTv,
  &__moodBtnTv {
    @mixin size 100%, 4.875rem;
    padding: 0.8125rem 0;
    color: var(--color_white-70);
    background-color: var(--color_secondaryActions-bg);
  }
}
