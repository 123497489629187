.recordingButton {
  --recording-inner-circle: #d80c2e;
  --recording-circle: #96071f;

  width: 100%;

  svg {
    height: 2.125rem;
    transition: none;
  }

  &:global(.tv__focus),
  &[data-tv-focus='true'] {
    @mixin tv-focus-button-base;

    --recording-inner-circle: var(--color-white);
    --recording-circle: #f88598;
  }

  &__text {
    margin-left: 1.1875rem;
    font-size: 1.6875rem;
  }
}
