@import '../../../../assets/base_css/_animations.css';

.heading {
  &__titleStickySentinel {
    width: 0.0625rem;
    height: 3.125rem;
  }

  &__titleWrapper {
    position: relative;

    display: flex;
    visibility: hidden;
    align-items: flex-end;
    min-height: 9rem;
    /* Prevent title from overlapping on trailer buttons */
    margin: 0 var(--horizontal-padding);
    &--fadeIn {
      @mixin fadeInAnimation;
    }

    @media (--mq-from-tablet) {
      max-width: 80%;
      min-height: 13.375rem;
      /* Prevent title from overlapping on channel logo */
      margin: 0 0 1.125rem 0;
    }

    @mixin tvDevice {
      padding: 0;
      margin: 0 0 0.5rem 0;
    }
  }

  &__title {
    position: absolute;
    left: 0;
    margin: 0;

    &-text {
      right: 0;
      padding: 1.875rem 1.5625rem 0;
      font-family: var(--font_canal);
      font-size: 1.625rem;
      line-height: 1.6875rem;
      color: var(--color_white);
      text-align: center;
      text-transform: uppercase;

      @mixin lineClamp 3, 11.6;

      @media (--mq-from-tablet) {
        max-width: 70%;
        padding: 1.875rem 1.5625rem 0 0;
        font-size: 3rem;
        line-height: 3.9375rem;
        text-align: inherit;
        transform: inherit;

        &--big {
          font-size: 2.5rem;
          line-height: 2.875rem;
        }
      }

      @media (--mq-from-desktop) {
        padding: 1.875rem 4rem 0 0;
      }

      @mixin tvDevice {
        max-width: 70%;
        padding: 0;
        font-size: 4.625rem;
        line-height: 5.375rem;

        &--big {
          font-size: 3.875rem;
          line-height: 4.5rem;
        }
      }
    }

    &-image {
      width: 100%;
      border: none;

      @media (--mq-from-tablet) {
        max-width: 23.75rem;
      }

      @mixin tvDevice {
        max-width: 40rem;
        margin-bottom: 0.625rem;
      }
    }
  }
}
