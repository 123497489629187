.moodButton {
  width: 100%;

  @mixin tvDevice {
    svg {
      fill: var(--color_white);
    }

    &--like svg {
      fill: var(--color_mood-like);
    }

    &--dislike svg {
      fill: var(--color_mood-dislike);
    }
  }

  &__badge {
    &--icon {
      svg {
        margin-left: 0.8rem;
      }
    }
  }

  &__label {
    margin-left: 1.1875rem;
    font-size: 1.6875rem;

    &--ellipsis {
      margin-right: 0.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
