.primaryActionButton {
  /* color for primaryActionButton (background hover) */
  --color-primaryactionbutton-bg-hover: var(--color_mine-shaft-light);
  --color-primaryactionbutton-bg-active: var(--color_cod-grey);
  --color-primaryactionbutton-svg-fill: var(--color_white);
  --color-primaryactionbutton-svg-fill-hover: var(--color_white);

  /* color for primaryActionButton-primary className */
  --color-btn-primary-bg: var(--color_amaranth);
  --color-btn-primary-bg-hover: #ee4a65;
  --color-btn-primary-bg-active: #d4314c;
  --color-btn-primary-svg-fill: var(--color_white);
  --color-btn-primary-svg-fill-hover: var(--color_white);

  --width_button: 100%;
  --size-button-svg-icon: 1.125rem;
  --size_button-deeplink-svg: 1.625rem;

  @mixin tvDevice {
    --width_button: 38.75rem;
    --size-button-svg-icon: 2.1875rem;
    --size_button-svg-icon-right-margin: 0.5rem;
    --size_button-deeplink-svg: 3rem;

    /* color for primaryActionButton-primary className (background, text and svg) */
    --color-btn-primary-bg: var(--color_mine-shaft);
    /* for tv device, the primary button hover color don't change we need it for LG Pointer*/
    --color-btn-primary-bg-hover: var(--color_mine-shaft);
    --color-primaryactionbutton-bg-hover: var(--color_mine-shaft);
  }

  [data-theme~='telecomitalia'] & {
    /* color for primaryActionButton (background hover and svg) */
    --color-primaryactionbutton-bg-hover: var(--color_dark);
    --color-primaryactionbutton-svg-fill: var(--color_dark);
    --color-primaryactionbutton-svg-fill-hover: var(--color_white);

    /* override css variables from Button.css for text button */
    --color_button-primary-fg: var(--color_dark);
    --color_button-primary-fg-hover: var(--color_white);

    /* color for primaryActionButton-primary className (background, text and svg) */
    --color-btn-primary-bg: var(--color_white);
    --color-btn-primary-bg-hover: var(--color_white);
    --color-btn-primary-bg-active: var(--color_white);
    --color-btn-primary-fg: var(--color_dark);
    --color-btn-primary-fg-hover: var(--color_dark);
    --color-btn-primary-fg-active: var(--color_dark);
    --color-btn-primary-svg-fill: var(--color_dark);
    --color-btn-primary-svg-fill-hover: var(--color_dark);

    @mixin tvDevice {
      /* override css variables from Button.css */
      --color_button-primary-fg: var(--color_white);

      /* color for primaryActionButton-primary className (background and svg) */
      --color-btn-primary-bg: var(--color_gold-kumera);
      --color-btn-primary-fg: var(--color_white);
      --color-btn-primary-fg-hover: var(--color_dark);
      --color-btn-primary-fg-active: var(--color_dark);
      --color-btn-primary-svg-fill: var(--color_white);
    }
  }

  [data-theme~='NEW-TIM'] & {
    --color-btn-primary-bg: var(--color_gold-atoll);
    --color-btn-primary-fg: var(--color_white);
    --color-btn-primary-fg-hover: var(--color_white);
    --color-btn-primary-fg-active: var(--color_white);
    --color-btn-primary-bg-hover: var(--color_gold-atoll);
    --color-btn-primary-bg-active: var(--color_gold-atoll);
    --color-btn-primary-svg-fill: var(--color_white);
    --color-btn-primary-svg-fill-hover: var(--color_white);

    @mixin tvDevice {
      --color-btn-primary-svg-fill: var(--color_white);
      --color-btn-primary-bg: var(--color_gold-kumera);
      --color-btn-primary-fg: var(--color_white);
      /* for tv device, the primary button hover color don't change we need it for LG Pointer*/
      --color-btn-primary-fg-hover: var(--color_white);
      --color-btn-primary-bg-hover: var(--color-btn-primary-bg);
    }
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: var(--width_button);
  height: 2.75rem;

  @media (--mq-from-tablet) {
    height: 3rem;
  }

  svg {
    @mixin size var(--size-button-svg-icon);
    margin-right: var(--size_button-svg-icon-right-margin);
    fill: var(--color-primaryactionbutton-svg-fill);
  }

  &:hover {
    background: var(--color-primaryactionbutton-bg-hover);

    svg {
      fill: var(--color-primaryactionbutton-svg-fill-hover);
    }
  }

  &:active {
    background: var(--color-primaryactionbutton-bg-active);
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  & > span {
    display: flex;
  }

  &__description {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--color_detail-metas);
    text-align: center;

    @mixin tvDevice {
      margin-bottom: 0.375rem;
      font-size: 1.6875rem;
      line-height: 2rem;
    }
  }

  &-primary {
    color: var(--color-btn-primary-fg);
    background: var(--color-btn-primary-bg);

    svg {
      fill: var(--color-btn-primary-svg-fill);
    }

    &:hover {
      color: var(--color-btn-primary-fg-hover);
      background: var(--color-btn-primary-bg-hover);

      svg {
        fill: var(--color-btn-primary-svg-fill-hover);
      }
    }

    &:active {
      color: var(--color-btn-primary-fg-active);
      background: var(--color-btn-primary-bg-active);
    }
  }

  &-unavailable {
    font-family: var(--font_hind);
    font-weight: 600;
    text-transform: unset;
    pointer-events: none;
    background: transparent;
    border: solid 2px #363636;
  }

  &-deeplink svg {
    @mixin size var(--size_button-deeplink-svg);
  }

  &:global(.tv__focus),
  &[data-tv-focus='true'] {
    @mixin tv-focus-button;
    background-color: var(--color_secondary);
    transform: scale(1.04);

    svg {
      fill: var(--color_focus-fg);
    }
  }
}
